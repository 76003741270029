@font-face {
  font-family: "Lora";
  src: url(/src/fonts/Lora/Lora-Italic-VariableFont_wght.ttf);
}

@font-face {
  font-family: "AirStrike";
  src: url(/src/fonts/airstrike/airstrike.ttf);
}

@font-face {
  font-family: "Montserrat";
  src: url(/src/fonts/montserrat/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Anita-Semi-Square";
  src: url(/src/fonts/anita-semi-square/Anita\ semi\ square.ttf);
}

@font-face {
  font-family: "Advent-Pro";
  src: url(/src/fonts/advent-pro/AdventPro-Medium.ttf);
}

@font-face {
  font-family: "Air-Milhouse";
  src: url(/src/fonts/air-millhouse/airmill_o.ttf);
}

@font-face {
  font-family: "Felix-Titling";
  src: url(/src/fonts/felixtitlingmt.ttf);
}

@font-face {
  font-family: "Agenda";
  src: url(/src/fonts/agenda/ufonts.com_agenda-mediumcondensed.ttf);
}

@font-face {
  font-family: "Roboto-Regular";
  src: url(/src/fonts/roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: "Roboto-Black";
  src: url(/src/fonts/roboto/Roboto-Black.ttf);
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(/src/fonts/roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: "Roboto-Medium";
  src: url(/src/fonts/roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: "Roboto-Thin";
  src: url(/src/fonts/roboto/Roboto-Thin.ttf);
}

.App {
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 500;
}

.company-name {
  font-family: 'AirStrike';
}

h1,h2,h3 {
  font-weight: 400;
  font-size: 42px;
  text-align: left;
}

p {
  font-weight: 400;
}
