form {
    width: 65%;
    margin-left: 60px;
}

.formRow {
    margin-bottom: 20px;
}

.formInput {
    padding: 15px 10px;
    border-radius: 10px;
    width: 300px;
}

.errorMessage {
    color: #f45532;
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.submit-btn {
    padding: 15px 30px;
    border-radius: 10px;
    color: #d0ff00;
    background-color: rgba(0, 167, 250, 0.8);
    align-content: center;
}