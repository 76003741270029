.services {
    padding: 40px;
    background-color: #f5f5f5;
  }
 
  .services ul {
    list-style-type: none;
    padding: 0;
    columns: 2;
  }
  
  .services li {
    margin-bottom: 10px;
  }
  