.navbar {
    background-color: #16ade3;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    font-family: 'Anita-Semi-Square';
    height: 90px;

    box-shadow: inset 0 0 2px #000, 
                inset 0 0 5px #000, 
                inset 0 0 4px #000;

}

.navbar .company-name {
    color: white;
    font-size: 80px;
    margin: 0;
    padding: 0;
    margin-left: 30px;
}

.nav-links {
    list-style-type: none;
    display: flex;
    padding-right: 20px;
    gap: 10px;
    font-size: 24px;
}

.nav-links a {
    color: #333;
    text-decoration: none;
    font-weight: bold;
}

.navbar li {
    margin: 0px 10px;
    color: black;
}

.navbar li:hover {
    transform: scale(1.2);
}

.navbar li a:hover {
    color: #d0ff00;
}