   /* Footer Styles */

.footer {
  background-color: #333;
  color: white;
  text-align: right;
  bottom: 0px;
  /* width: 100%; */
  padding: 5px;
  font-family: 'Felix-Titling';
}

/* Social Media Icons */
.footer .social-media-links {
  display: flex;
  justify-content: left;
}

.footer .social-media-links a img {
  width: 20px; /* Size of the icons */
  height: 20px;
  margin-left: 20px;
}

.footer div:last-child {
  padding-right: 10px;
  margin-top: 2px;
}

#copyright {
  flex:auto;
}