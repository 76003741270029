.contact {
  padding: 40px;
  background-color: #001027d2;
  color: rgb(255, 255, 255);
  flex-direction: column;
  column-count: 2;
  font-size: 20px;
}

.contact-info {
  text-align: left;
  margin-left: 25%;
}

.contact-info a {
  color: rgb(0, 255, 200);
}

a:hover {
  font-weight: bold;
}

span:nth-child(-n+3) {
  line-height: 30px;
}

span:nth-child(3) {
  line-height: 45px;
}