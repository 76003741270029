.intro {
  position: relative;
  height: 400px;
  background-image: url('/src/images/SMTXLAW_leaves_collage_darkblueBG.png');
  background-blend-mode:exclusion;
  background-size: 20%;
  background-position-y: 40px;
  background-position-x: 40px;
 

  flex: 1;
  display: flex;
  justify-content: right;
  align-items: center;

  box-shadow: inset 0 0 120px #000, inset 0 0 80px #000, inset 0 0 40px #000;

}

.intro-body {
  font-family: 'Agenda';
  font-size:45px;
  line-height: 46px;
  font-weight: 400;
  justify-content: right;
  margin-right: 200px;

  color: white;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 14px;
}