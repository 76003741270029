.about {
  padding: 40px;
  text-align: center;
  background-image: url('../images/photorealistic_image_of_a_Texas_courthouse.webp');
  background-repeat: no-repeat;
  background-size: contain;

  max-height: 490px;
}

.about-image {
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}


#lawfirm-philosophy {
  display: inline-block;
  width: 800px;
  margin-left: 800px;
}

#lawfirm-philosophy p {
  font-size: 20px;
}